// src/hooks/use3DDataRealtimeTemperatures.ts
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setRealtimeRawTemperatures } from 'src/store/reducers/realtimeRawTemperaturesSlice';
import { setRealtimeTemperatures } from 'src/store/reducers/realtimeTemperturesSlice';
import { setIntervalRealtimeGraph } from 'src/store/reducers/realTimeVisualization';
import { API } from 'src/api/API';

const use3DDataRealtimeTemperatures = (
    objectId: number,
    start: string,
    end: string,
) => {
    const dispatch = useDispatch();

    // Local states for loading, error, and data
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        // Start loading and fetch new data
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const response = await new API().fetchTemperaturesRealTimeVisualization(
                    objectId,
                    start,
                    end,
                );

                // Store raw data and interval in Redux
                dispatch(
                    setRealtimeRawTemperatures({
                        key: `${objectId}-${start}-${end}`,
                        data: response['raw_data'] || [],
                    }),
                );

                dispatch(setRealtimeTemperatures(response?.data));
                dispatch(setIntervalRealtimeGraph(response['interval'] || 1));

                // Update local data state
                setData(response?.data);
            } catch (e: any) {
                setError(e?.message || 'Failed to fetch data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, objectId, start, end]);

    return { data, isLoading, error };
};

export default use3DDataRealtimeTemperatures;
