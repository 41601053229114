import { API } from 'src/api/API';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAvgObjTemperatures } from 'src/store/reducers/avgObjTemperaturesSlice';

const useAvgTempObjRealTime = (
    objectId: number,
    start: string,
    end: string,
) => {
    const dispatch = useDispatch();

    // Local states for loading, error, and data
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        // Start loading and fetch new data
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const response = await new API().fetchRealTimeWithAvgObj(
                    objectId,
                    start,
                    end,
                    101,
                );
                const dataToPush = [];

                if (response.avgTemp && response.avgTemp.length) {
                    dataToPush.push({
                        id: Math.floor(Math.random() * 9000000) + 1000000,
                        name: 'Medeltemperatur',
                        sensorFunctionTypeId: -1,
                        data: response.avgTemp,
                    });
                }

                // Store raw data in Redux
                dispatch(
                    setAvgObjTemperatures({
                        key: `${objectId}-${start}-${end}`,
                        data: dataToPush || [],
                    }),
                );

                // Update local data state
                setData(dataToPush);
            } catch (e: any) {
                setError(e?.message || 'Failed to fetch data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, objectId, start, end]);

    return { data, isLoading, error };
};

export default useAvgTempObjRealTime;
